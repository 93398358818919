import { Line } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import './Item.scss';
import { Link } from 'react-router-dom';

Chart.register(CategoryScale);

const down = (ctx, value) =>
	ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

const up = (ctx, value) =>
	ctx.p0.parsed.y < ctx.p1.parsed.y ? value : undefined;

function Item({ id, name, priceHistory }) {
	const price = priceHistory[priceHistory.length - 1].price;
	const boughtAt = priceHistory[0].price;

	const margin = Math.round(price - boughtAt);
	const marginSign = margin >= 0 ? '' : '-';
	const displayMargin = Math.abs(margin);

	const marginPercent = Math.round((margin / boughtAt) * 100);
	const marginPercentSign = marginPercent >= 0 ? '+' : '';

	const displayPrice = Math.round(price);
	const displayBoughtAt = Math.round(boughtAt);

	const chartData = {
		labels: priceHistory.map((day) => {
			const itemDate = new Date(day.date);

			return `${itemDate.getMonth() + 1}/${itemDate.getDate()}`;
		}),
		datasets: [
			{
				data: priceHistory.map((day) => Math.round(day.price)),
				borderColor: 'rgb(99, 99, 102)',
				segment: {
					borderColor: (ctx) =>
						down(ctx, 'rgb(225, 69, 58)') ||
						up(ctx, 'rgb(48, 209, 88)'),
				},
				spanGaps: true,
			},
		],
	};

	const priceMin = Math.round(
		Math.min(...priceHistory.map((day) => day.price))
	);
	const priceMax = Math.round(
		Math.max(...priceHistory.map((day) => day.price))
	);

	return (
		<Link to={`/item/${id}`} className='item-link' key={id}>
			<div className='item' key={id}>
				<Line
					className='item-pricing-chart'
					data={chartData}
					options={{
						responsive: true,
						tooltips: {
							callbacks: {
								label: function (tooltipItem) {
									return '';
								},
							},
						},
						plugins: {
							legend: {
								display: false,
							},
							title: {
								display: false,
							},
							tooltip: {
								enabled: false,
							},
						},
						scales: {
							y: {
								display: false,
								min: priceMin - 10,
								max: priceMax + 10,
							},
							x: {
								display: false,
							},
						},
					}}
				/>
				<div className='item-details'>
					<div className='item-name'>{name}</div>
				</div>
				<div className='item-pricing'>
					<div className='item-detail'>
						<span className='detail-label'>Current:</span>
						<span className='item-price'>${displayPrice}</span>
					</div>
					<div className='item-detail'>
						<span className='detail-label'>Bought:</span>
						<span className='item-bought-at'>
							${displayBoughtAt}
						</span>
					</div>
					<div className='item-detail'>
						<span className='detail-label'>Gain:</span>
						<span
							className={`item-margin ${
								margin >= 0
									? 'item-margin-positive'
									: 'item-margin-negative'
							}`}>
							{marginSign}${displayMargin}{' '}
							<span
								className={`item-margin-percent ${
									marginPercent >= 0
										? 'item-margin-percent-positive'
										: 'item-margin-percent-negative'
								}`}>
								{marginPercentSign}
								{marginPercent}%
							</span>
						</span>
					</div>
				</div>
			</div>
		</Link>
	);
}

export default Item;
