import { Component } from 'react';
import { Link, useParams } from 'react-router-dom';
import './Item.scss';
import HistoryChart from 'Components/shared/HistoryChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTrash,
	faTrashAlt,
	faXmarkSquare,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'Components/shared/Button';
import HorizontalSelector from 'Components/shared/HorizontalSelector';
import { getCookie } from 'modules/cookies';
import web from 'modules/web/web';

class Item extends Component {
	constructor() {
		super();

		this.state = {
			timeframe: '1M',
			loaded: false,
		};
	}

	async removeItem() {
		const { id } = this.props;

		const { error } = await web.removeItem({
			id,
			email: getCookie('email'),
			password: getCookie('password'),
		});

		if (error) {
			return { error };
		}

		window.location = '/';
	}

	async getAlerts() {
		const { id } = this.props;

		const priceAlerts = await web.getPriceAlerts({
			email: getCookie('email'),
			password: getCookie('password'),
			id,
		});

		return priceAlerts;
	}

	async componentDidMount() {
		const { id } = this.props;

		const items = await web.getItems({
			email: getCookie('email'),
			password: getCookie('password'),
		});

		const priceAlerts = await this.getAlerts();

		this.setState({
			item: items.find((item) => item.id === id),
			priceAlerts,
			loaded: true,
		});
	}

	changeTimeframe(option) {
		this.setState({ timeframe: option });
	}

	async deleteAlert(alertId) {
		const { id } = this.props;
		const { error } = await web.removePriceAlert({
			alertId,
			itemId: id,
			email: getCookie('email'),
			password: getCookie('password'),
		});

		if (error) {
			return { error };
		}

		const priceAlerts = await this.getAlerts();

		this.setState({ priceAlerts });
	}

	render() {
		const { item, timeframe, loaded, priceAlerts } = this.state;

		if (!loaded) return;

		let priceHistory = {};

		item.priceHistory.forEach((day) => {
			const date = day.date;

			if (timeframe === '1D') {
				const itemDate = new Date(date);

				if (itemDate.getDate() !== new Date().getDate()) return;
			}

			if (timeframe === '1W') {
				const itemDate = new Date(date);

				if (itemDate.getDate() < new Date().getDate() - 7) return;
			}

			if (timeframe === '1M') {
				const itemDate = new Date(date);

				if (itemDate.getMonth() !== new Date().getMonth()) return;
			}

			if (timeframe === '1Y') {
				const itemDate = new Date(date);

				if (itemDate.getFullYear() !== new Date().getFullYear()) return;
			}

			if (priceHistory[date]) {
				priceHistory[date] += day.price;
			} else {
				priceHistory[date] = day.price;
			}
		});

		const price = item.priceHistory[item.priceHistory.length - 1].price;
		const boughtAt = item.priceHistory[0].price;

		const margin = Math.round(price - boughtAt);
		const marginSign = margin >= 0 ? '' : '-';
		const displayMargin = Math.abs(margin);

		const marginPercent = Math.round((margin / boughtAt) * 100);
		const marginPercentSign = marginPercent >= 0 ? '+' : '';

		const displayPrice = Math.round(price);
		const displayBoughtAt = Math.round(boughtAt);

		const priceAlertsMap = priceAlerts?.map((alert, index) => {
			if (alert.price) {
				const displayPrice = Math.round(alert.price);

				const margin = Math.round(alert.price - boughtAt);
				const marginSign = margin >= 0 ? '+' : '-';

				const displayMargin = Math.abs(margin);

				const marginPercent = Math.round((margin / boughtAt) * 100);
				const marginPercentSign = marginPercent >= 0 ? '+' : '';

				return (
					<div className='alert' key={index}>
						<div className='alert-section'>
							<span className='alert-label'>Alert at:</span>
							<span className='alert-value'>
								${displayPrice}{' '}
								<span
									className={`alert-value ${
										margin >= 0
											? 'alert-value-positive'
											: 'alert-value-negative'
									}`}>
									{marginSign}${displayMargin}{' '}
									{marginPercentSign}
									{marginPercent}%
								</span>
							</span>
						</div>
						<FontAwesomeIcon
							icon={faTrashAlt}
							className='alert-delete'
							onClick={this.deleteAlert.bind(this, alert.id)}
						/>
					</div>
				);
			}

			const percentPrice =
				alert.percent &&
				Math.round(boughtAt * (1 + alert.percent / 100));

			const percentPriceMargin = Math.round(percentPrice - boughtAt);
			const percentPriceMarginSign = percentPriceMargin >= 0 ? '+' : '-';
			const displayPercentPriceMargin = Math.abs(percentPriceMargin);

			return (
				<div className='alert' key={index}>
					<div className='alert-section'>
						<span className='alert-label'>Alert at:</span>
						<span className='alert-value'>
							{alert.percent}% at ${percentPrice} (
							<span
								className={`alert-value ${
									percentPriceMargin >= 0
										? 'alert-value-positive'
										: 'alert-value-negative'
								}`}>
								{percentPriceMarginSign}$
								{displayPercentPriceMargin}
							</span>
							)
						</span>
					</div>
					<FontAwesomeIcon
						icon={faTrashAlt}
						className='alert-delete'
						onClick={this.deleteAlert.bind(this, alert.id)}
					/>
				</div>
			);
		});

		return (
			<div className='item-page'>
				<span className='item-name'>{item.name}</span>
				<div className='item-chart-container'>
					<HistoryChart history={priceHistory} />
					<div className='horizontal-selector-container'>
						<HorizontalSelector
							onOptionSelected={this.changeTimeframe.bind(this)}
							options={['1D', '1W', '1M', '1Y', 'ALL']}
							selected={'1M'}
						/>
					</div>
				</div>
				<div className='item-details'>
					<div className='item-detail'>
						<span className='detail-label'>Current:</span>
						<span className='detail-value'>${displayPrice}</span>
					</div>
					<div className='item-detail'>
						<span className='detail-label'>Bought at:</span>
						<span className='detail-value'>${displayBoughtAt}</span>
					</div>
					<div className='item-detail'>
						<span className='detail-label'>Gain:</span>
						<span
							className={`detail-value ${
								margin >= 0
									? 'detail-value-positive'
									: 'detail-value-negative'
							}`}>
							{marginSign}${displayMargin}
						</span>
						<span
							className={`detail-value ${
								margin >= 0
									? 'detail-value-positive'
									: 'detail-value-negative'
							}`}>
							{marginPercentSign}
							{marginPercent}%
						</span>
					</div>
				</div>
				<div className='item-price-alerts'>
					<span className='item-price-alerts-title'>
						Price Alerts
					</span>
					{(!priceAlerts || priceAlerts.length === 0) && (
						<div className='item-price-alerts-none'>
							No price alerts set.
						</div>
					)}
					{priceAlertsMap}
					<Link to={`/addPriceAlert/${item.id}`}>
						<Button label='Add' success className='alert-add' />
					</Link>
				</div>
				<div className='item-controls'>
					<Button
						label='Remove'
						icon={faTrash}
						danger
						className='item-remove'
						onClick={this.removeItem.bind(this)}
					/>
				</div>
			</div>
		);
	}
}

function ItemWrapper(props) {
	const { id } = useParams();

	return <Item id={id} {...props} />;
}

export default ItemWrapper;
