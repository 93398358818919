import axios from 'axios';

const URL =
	window.location.origin.includes('localhost') ||
	window.location.origin.includes('127.0.0.1')
		? 'http://localhost:8080'
		: 'https://api.worthwatch.co';

async function getItems({ email, password }) {
	try {
		const { data } = await axios.post(`${URL}/get-items`, {
			email,
			password,
		});

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function addItem(
	{ name, link, boughtAt, boughtAtDate, condition },
	{ email, password }
) {
	try {
		const { data } = await axios.post(`${URL}/add-item`, {
			name,
			link,
			email,
			password,
			boughtAt,
			boughtAtDate,
			condition,
		});

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function signUp({ email, password }) {
	try {
		const { data } = await axios.post(`${URL}/signup`, {
			email,
			password,
		});

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function login({ email, password }) {
	try {
		const { data } = await axios.post(`${URL}/login`, {
			email,
			password,
		});

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function removeItem({ id, email, password }) {
	try {
		const { data } = await axios.post(`${URL}/remove-item`, {
			email,
			password,
			id,
		});

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function addPriceAlert({ id, email, password, price, percent }) {
	try {
		const { data } = await axios.post(`${URL}/add-price-alert`, {
			email,
			password,
			id,
			price,
			percent,
		});

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function getPriceAlerts({ email, password, id }) {
	try {
		const { data } = await axios.post(`${URL}/get-price-alerts`, {
			email,
			password,
			id,
		});

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function removePriceAlert({ email, password, alertId, itemId }) {
	try {
		const { data } = await axios.post(`${URL}/remove-price-alert`, {
			email,
			password,
			itemId,
			alertId,
		});

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function deleteAccount({ email, password }) {
	try {
		const { data } = await axios.post(`${URL}/delete-account`, {
			email,
			password,
		});

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function getPhoneNumber({ email, password }) {
	try {
		const { data } = await axios.post(`${URL}/get-phone-number`, {
			email,
			password,
		});

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function updatePhoneNumber({ email, password, phoneNumber }) {
	try {
		const { data } = await axios.post(`${URL}/update-phone-number`, {
			email,
			password,
			phoneNumber,
		});

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function setAllowSMSNotifications({
	email,
	password,
	allowSMSNotifications,
}) {
	try {
		const { data } = await axios.post(
			`${URL}/set-allow-sms-notifications`,
			{
				email,
				password,
				allowSMSNotifications,
			}
		);

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function setAllowEmailNotifications({
	email,
	password,
	allowEmailNotifications,
}) {
	try {
		const { data } = await axios.post(
			`${URL}/set-allow-email-notifications`,
			{
				email,
				password,
				allowEmailNotifications,
			}
		);

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function getAllowEmailNotifications({ email, password }) {
	try {
		const { data } = await axios.post(
			`${URL}/get-allow-email-notifications`,
			{
				email,
				password,
			}
		);

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

async function getAllowSMSNotifications({ email, password }) {
	try {
		const { data } = await axios.post(
			`${URL}/get-allow-sms-notifications`,
			{
				email,
				password,
			}
		);

		return data;
	} catch (err) {
		console.error(err);

		return { error: 'Internal Error' };
	}
}

export default {
	getItems,
	addItem,
	signUp,
	login,
	removeItem,
	addPriceAlert,
	getPriceAlerts,
	removePriceAlert,
	deleteAccount,
	updatePhoneNumber,
	getPhoneNumber,
	setAllowSMSNotifications,
	getAllowSMSNotifications,
	setAllowEmailNotifications,
	getAllowEmailNotifications,
};
