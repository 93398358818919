import { Line } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import './HistoryChart.scss';

Chart.register(CategoryScale);

const down = (ctx, value) =>
	ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

const up = (ctx, value) =>
	ctx.p0.parsed.y < ctx.p1.parsed.y ? value : undefined;

function HistoryChart({ history }) {
	const historyMin = Math.round(
		Math.min(...Object.values(history).map((price) => price))
	);
	const historyMax = Math.round(
		Math.max(...Object.values(history).map((price) => price))
	);

	const data = {
		labels: Object.keys(history).map((date) => {
			const itemDate = new Date(date);

			return `${itemDate.getMonth() + 1}/${itemDate.getDate()}`;
		}),
		datasets: [
			{
				data: Object.values(history).map((price) => Math.round(price)),
				borderColor: 'rgb(99, 99, 102)',
				segment: {
					borderColor: (ctx) =>
						down(ctx, 'rgb(225, 69, 58)') ||
						up(ctx, 'rgb(48, 209, 88)'),
				},
				spanGaps: true,
			},
		],
	};

	return (
		<Line
			className='history-chart'
			data={data}
			options={{
				responsive: true,
				plugins: {
					legend: {
						display: false,
					},
					title: {
						display: false,
					},
					tooltip: {
						callbacks: {
							label: (ctx) => {
								if (!ctx.parsed.y) return '';

								return '$' + ctx.parsed.y;
							},
						},
					},
				},
				scales: {
					y: {
						min: historyMin - 10,
						max: historyMax + 10,
						ticks: {
							callback: (value, index, values) => {
								return '$' + value;
							},
						},
					},
				},
			}}
		/>
	);
}

export default HistoryChart;
