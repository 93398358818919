import { Component } from 'react';
import HorizontalSelector from 'Components/shared/HorizontalSelector';
import './Totals.scss';
import HistoryChart from 'Components/shared/HistoryChart';

class Totals extends Component {
	constructor() {
		super();

		this.state = {
			timeframe: '1M',
		};
	}

	calculateTotalNetWorth() {
		const { items } = this.props;

		let total = 0;

		items.forEach((item) => {
			const price = item.priceHistory[item.priceHistory.length - 1].price;
			total += price;
		});

		total = Math.round(total);

		this.setState({ totalNetWorth: total });
	}

	componentDidMount() {
		this.calculateTotalNetWorth();
	}

	changeTimeframe(option) {
		this.setState({ timeframe: option });
	}

	render() {
		const { totalNetWorth, timeframe } = this.state;
		const { items } = this.props;

		let worthHistory = {};

		items.forEach((item) => {
			item.priceHistory.forEach((day) => {
				const date = day.date;

				if (timeframe === '1D') {
					const itemDate = new Date(date);

					if (itemDate.getDate() !== new Date().getDate()) return;
				}

				if (timeframe === '1W') {
					const itemDate = new Date(date);

					if (itemDate.getDate() < new Date().getDate() - 7) return;
				}

				if (timeframe === '1M') {
					const itemDate = new Date(date);

					if (itemDate.getMonth() !== new Date().getMonth()) return;
				}

				if (timeframe === '1Y') {
					const itemDate = new Date(date);

					if (itemDate.getFullYear() !== new Date().getFullYear())
						return;
				}

				if (!worthHistory[date]) {
					worthHistory[date] = day.price;

					return;
				}

				worthHistory[date] += day.price;
			});
		});

		let dayWorthMargin, dayWorthMarginPercent;

		if (Object.values(worthHistory).length < 2) {
			dayWorthMargin = 0;
			dayWorthMarginPercent = 0;
		} else {
			dayWorthMargin = Math.round(
				worthHistory[
					Object.keys(worthHistory)[
						Object.keys(worthHistory).length - 1
					]
				] -
					worthHistory[
						Object.keys(worthHistory)[
							Object.keys(worthHistory).length - 2
						]
					]
			);

			dayWorthMarginPercent = Math.round(
				(dayWorthMargin /
					worthHistory[
						Object.keys(worthHistory)[
							Object.keys(worthHistory).length - 2
						]
					]) *
					100
			);
		}

		const dayWorthMarginSign = dayWorthMargin >= 0 ? '' : '-';
		const displayDayWorthMargin = Math.abs(dayWorthMargin);

		const dayWorthMarginPercentSign =
			dayWorthMarginPercent >= 0 ? '+' : '-';
		const displayDayWorthMarginPercent = Math.abs(dayWorthMarginPercent);

		return (
			totalNetWorth && (
				<div className='widget'>
					<span className='total-net-worth'>
						Total net worth: ${totalNetWorth} (
						<span
							className={`total-net-worth-margin ${
								dayWorthMargin >= 0
									? 'total-net-worth-margin-positive'
									: 'total-net-worth-margin-negative'
							}`}>
							{dayWorthMarginSign}${displayDayWorthMargin}{' '}
							{dayWorthMarginPercentSign}
							{displayDayWorthMarginPercent}%
						</span>{' '}
						from yesterday)
					</span>
					<HistoryChart history={worthHistory} />
					<HorizontalSelector
						onOptionSelected={this.changeTimeframe.bind(this)}
						options={['1D', '1W', '1M', '1Y', 'ALL']}
						selected={'1M'}
					/>
				</div>
			)
		);
	}
}

export default Totals;
