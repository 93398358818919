import { Component } from 'react';
import './Home.scss';
import Totals from 'Components/containers/Widgets/Totals';
import Item from './Item';
import { getCookie } from 'modules/cookies';
import web from 'modules/web/web';

class Home extends Component {
	constructor() {
		super();

		this.state = {
			loaded: false,
		};
	}

	async componentDidMount() {
		const items = await web.getItems({
			email: getCookie('email'),
			password: getCookie('password'),
		});

		this.setState({ items, loaded: true });
	}

	render() {
		const { items, loaded } = this.state;

		if (!loaded) return;

		const itemsMap = items?.map((item, index) => {
			return <Item {...item} id={item.id} />;
		});

		return (
			loaded && (
				<div className='home'>
					<div className='section'>
						<div className='section-label'>
							<span className='section-title'>Widgets</span>
							<span className='section-description'>
								Easy access to information about your assets
							</span>
						</div>
						<div className='section-items'>
							{itemsMap.length === 0 ? (
								<div className='section-items'>
									You have no assets!
								</div>
							) : (
								<Totals items={items} />
							)}
						</div>
					</div>
					<div className='section'>
						<div className='section-label'>
							<span className='section-title'>Assets</span>
							<span className='section-description'>
								All of the assets you are keeping track of
							</span>
						</div>
						{itemsMap.length === 0 && (
							<div className='section-items'>
								You have no assets!
							</div>
						)}
						<div className='section-items'>{itemsMap}</div>
					</div>
				</div>
			)
		);
	}
}

export default Home;
