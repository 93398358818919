function validateEmail(email) {
	return email?.match(
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
}

function validatePassword(password) {
	return password?.length >= 6;
}

function validatePhoneNumber(phoneNumber) {
	return phoneNumber?.match(/^[0-9]{10}$/);
}

export { validateEmail, validatePassword, validatePhoneNumber };
