import { Component } from 'react';
import './AddItem.scss';
import Form from 'Components/containers/Form';
import { getCookie } from 'modules/cookies';
import web from 'modules/web/web';

class AddItem extends Component {
	constructor() {
		super();

		this.state = {};
	}

	async addItem(setLoading, values) {
		setLoading(true);

		const { error } = await web.addItem(values, {
			email: getCookie('email'),
			password: getCookie('password'),
		});

		if (error) {
			setLoading(false);

			return { error };
		}

		setLoading(false);
	}

	render() {
		return (
			<Form
				inputs={[
					{
						label: 'Name',
						placeholder: 'Enter the item name',
						required: true,
						variable: 'name',
					},
					{
						label: 'Bought At Price',
						variable: 'boughtAt',
						placeholder: 'Enter the price it was bought at',
						prefix: '$',
						number: true,
						requiredFor: 'boughtAtDate',
					},
					{
						label: 'Bought At Date',
						variable: 'boughtAtDate',
						type: 'date',
						placeholder: 'Enter the date it was bought at',
						requiredFor: 'boughtAt',
					},
					{
						label: "Don't remember how much you bought it for? We'll use the current price.",
						type: 'caption',
					},
					{
						label: 'Link',
						variable: 'link',
						placeholder: 'Enter the shopping link to the item',
					},
					{
						label: "No shopping link? That's okay! We'll automatically find one for you.",
						type: 'caption',
					},
					{
						label: 'Condition',
						variable: 'condition',
						type: 'dropdown',
						options: [
							'New',
							'Used',
							'Open Box',
							'Refurbished',
							'Not Working',
						],
						default: 'New',
					},
					{
						type: 'submit',
						label: 'Add Asset',
					},
				]}
				onSubmit={this.addItem.bind(this)}
				title='Add Asset'
				subtitle='Add an automated asset to your portfolio'
			/>
		);
	}
}

export default AddItem;
