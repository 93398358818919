function addHyphens(phoneNumber) {
	return phoneNumber
		.replace(/([^\d])/g, '')
		.replace(
			/(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
			'$1$2$3-$4$5$6-$7$8$9$10'
		);
}

function removeHyphens(phoneNumber) {
	return phoneNumber.replace(/-/g, '');
}

export { addHyphens, removeHyphens };
