import Navbar from './Components/containers/Navbar';
import './App.scss';
import Home from 'Components/pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Item from 'Components/pages/Item';
import Login from 'Components/pages/UserForms/Login';
import SignUp from 'Components/pages/UserForms/SignUp';
import { Component } from 'react';
import { getCookie } from 'modules/cookies';
import AddItem from 'Components/pages/AddItem';
import web from 'modules/web/web';
import AddPriceAlert from 'Components/pages/AddPriceAlert';
import User from 'Components/pages/User';

class App extends Component {
	constructor() {
		super();

		this.state = {
			loggedIn: true,
			loaded: false,
		};
	}

	async componentDidMount() {
		const email = getCookie('email') || '';
		const password = getCookie('password') || '';

		const loggedIn = await web.login({
			email,
			password,
		});

		this.setState({
			loggedIn: loggedIn.success && !loggedIn.error,
			loaded: true,
		});
	}

	render() {
		const { loggedIn, loaded } = this.state;

		return (
			loaded && (
				<BrowserRouter>
					<div className='content'>
						<div className='body'>
							{loggedIn ? (
								<Routes>
									<Route path='*' element={<Home />} />
									<Route
										path='/item/:id'
										element={<Item />}
									/>
									<Route
										path='/addItem'
										element={<AddItem />}
									/>
									<Route path='/user' element={<User />} />
									<Route
										path='/addPriceAlert/:id'
										element={<AddPriceAlert />}
									/>
									<Route path='/login' element={<Login />} />
								</Routes>
							) : (
								<Routes>
									<Route path='*' element={<Login />} />
									<Route path='/login' element={<Login />} />
									<Route
										path='/signUp'
										element={<SignUp />}
									/>
								</Routes>
							)}
						</div>
						{loggedIn && <Navbar />}
					</div>
				</BrowserRouter>
			)
		);
	}
}

export default App;
