import { Component } from 'react';
import './AddPriceAlert.scss';
import { useParams } from 'react-router-dom';
import Form from 'Components/containers/Form';
import web from 'modules/web/web';
import { getCookie } from 'modules/cookies';

class AddPriceAlert extends Component {
	constructor() {
		super();

		this.state = {};
	}

	async addPriceAlert(setLoading, values) {
		const { id } = this.props;

		setLoading(true);

		const { price, percent } = values;

		const { error } = await web.addPriceAlert({
			id,
			price,
			percent,
			email: getCookie('email'),
			password: getCookie('password'),
		});

		if (error) {
			setLoading(false);

			return { error };
		}

		setLoading(false);
	}

	render() {
		return (
			<Form
				inputs={[
					{
						label: 'Price',
						placeholder: 'Enter the price to alert you at',
						variable: 'price',
						prefix: '$',
						number: true,
					},

					{
						label: "Adding a price alert will alert you when the item's price reaches or goes above the value you set",
						type: 'caption',
					},
					{
						label: 'Percent',
						variable: 'percent',
						placeholder: 'Enter the percent to alert you at',
						prefix: '%',
						number: true,
					},
					{
						label: 'Adding a percent alert will alert you when the percent of the amount of money gained by selling the item reaches or goes above the value you set',
						type: 'caption',
					},
					{
						type: 'submit',
						label: 'Add Price Alert',
					},
				]}
				onSubmit={this.addPriceAlert.bind(this)}
				title='Add Price Alert'
				subtitle='Add a price alert for this item'
			/>
		);
	}
}

function AddPriceAlertWrapper(props) {
	const { id } = useParams();

	return <AddPriceAlert id={id} {...props} />;
}

export default AddPriceAlertWrapper;
