import { Component } from 'react';
import './SignUp.scss';
import { validateEmail, validatePassword } from 'modules/validation/user';
import Form from 'Components/containers/Form';
import { setCookie } from 'modules/cookies';
import web from 'modules/web/web';
import { Link } from 'react-router-dom';

class SignUp extends Component {
	constructor() {
		super();

		this.state = {};
	}

	async submit(setLoading, values) {
		setLoading(true);

		const { email, password } = values;

		const { error } = await web.signUp({
			email,
			password,
		});

		if (error) {
			setLoading(false);

			return { error };
		}

		setCookie('email', email);
		setCookie('password', password);

		setLoading(false);

		return { error: null };
	}

	render() {
		return (
			<Form
				onSubmit={this.submit.bind(this)}
				title='Sign Up'
				subtitle={"Don't have an account? Let's make one."}
				alternativeLink={{
					text: 'Login',
					to: '/login',
				}}
				inputs={[
					{
						type: 'email',
						placeholder: 'Enter your email',
						label: 'Email',
						variable: 'email',
						required: true,
						validate: {
							validator: validateEmail,
							message: 'Please enter a valid email.',
						},
					},
					{
						type: 'password',
						variable: 'password',
						placeholder: 'Enter a password',
						label: 'Password',
						required: true,
						validate: {
							validator: validatePassword,
							message: 'Please enter a valid password.',
						},
					},
					{
						type: 'password',
						variable: 'confirmPassword',
						placeholder: 'Enter your password again',
						label: 'Confirm Password',
						required: true,
						confirmFor: 'password',
						validate: {
							validator: validatePassword,
							message: 'Please enter a valid password.',
						},
					},
					{
						type: 'submit',
						label: 'Sign Up',
					},
				]}
			/>
		);
	}
}

export default SignUp;
