import { Component } from 'react';
import './HorizontalSelector.scss';

class HorizontalSelector extends Component {
	constructor({ selected, options }) {
		super();

		this.state = {
			selected: options.indexOf(selected),
		};
	}

	setOptionSelected(index) {
		const { onOptionSelected, options } = this.props;

		if (onOptionSelected) {
			onOptionSelected(options[index]);
		}

		this.setState({ selected: index });
	}

	render() {
		const { options } = this.props;
		const { selected } = this.state;

		const optionsMap = options.map((option, index) => {
			const isSelected = selected === index;

			return (
				<div
					className={`option ${isSelected && 'option-selected'}`}
					key={index}
					onClick={this.setOptionSelected.bind(this, index)}>
					<div className='option-label'>{option}</div>
				</div>
			);
		});

		return <div className='horizontal-selector'>{optionsMap}</div>;
	}
}

export default HorizontalSelector;
