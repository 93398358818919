import { Component } from 'react';
import './User.scss';
import Button from 'Components/shared/Button';
import {
	faArrowRightFromBracket,
	faEnvelope,
	faMailBulk,
	faMobilePhone,
	faPhone,
	faUserXmark,
} from '@fortawesome/free-solid-svg-icons';
import { deleteCookie, getCookie } from 'modules/cookies';
import web from 'modules/web/web';
import Input from 'Components/shared/Input';
import Form from 'Components/containers/Form';
import { validatePhoneNumber } from 'modules/validation/user';
import { addHyphens, removeHyphens } from 'modules/utils/phoneNumber';
import Toggle from 'Components/shared/Toggle';

class User extends Component {
	constructor() {
		super();

		this.state = {
			errors: {},
			loading: {},
			loaded: false,
		};
	}

	async componentDidMount() {
		const email = getCookie('email');
		const password = getCookie('password');

		const { phoneNumber } = await web.getPhoneNumber({
			email,
			password,
		});

		const allowSMSNotifications = await web.getAllowSMSNotifications({
			email,
			password,
		});

		const allowEmailNotifications = await web.getAllowEmailNotifications({
			email,
			password,
		});

		this.setState({
			phoneNumber,
			allowSMSNotifications,
			allowEmailNotifications,
			loaded: true,
		});
	}

	logout() {
		deleteCookie('email');
		deleteCookie('password');

		window.location = '/';
	}

	async updatePhoneNumber() {
		let { phoneNumber, errors, loading } = this.state;

		phoneNumber = removeHyphens(phoneNumber);

		if (!phoneNumber) {
			this.setState({
				errors: {
					...errors,
					phoneNumber: 'Please enter a phone number',
				},
			});

			return;
		}

		if (!validatePhoneNumber(phoneNumber)) {
			this.setState({
				errors: {
					...errors,
					phoneNumber: 'Please enter a valid phone number',
				},
			});

			return;
		}

		this.setState(
			{
				loading: {
					...loading,
					phoneNumber: true,
				},
				errors: {
					...errors,
					phoneNumber: null,
				},
			},
			async () => {
				const email = getCookie('email');
				const password = getCookie('password');

				const { error } = await web.updatePhoneNumber({
					email,
					password,
					phoneNumber,
				});

				if (error) {
					console.error(error);

					this.setState({
						errors: {
							...errors,
							phoneNumber: 'Internal Error',
						},
						loading: {
							...loading,
							phoneNumber: false,
						},
					});

					return;
				}

				this.setState({
					loading: {
						...loading,
						phoneNumber: false,
					},
				});
			}
		);
	}

	async updateAllowSMSNotifications(enabled) {
		const email = getCookie('email');
		const password = getCookie('password');

		const { error } = await web.setAllowSMSNotifications({
			email,
			password,
			allowSMSNotifications: enabled,
		});

		if (error) {
			console.error(error);

			return;
		}
	}

	async updateAllowEmailNotifications(enabled) {
		const email = getCookie('email');
		const password = getCookie('password');

		const { error } = await web.setAllowEmailNotifications({
			email,
			password,
			allowEmailNotifications: enabled,
		});

		if (error) {
			console.error(error);

			return;
		}
	}

	handleChangePhoneNumber(value) {
		const { phoneNumber } = this.state;
		// phoneNumber = addHyphens(phoneNumber);

		// this.setState({ phoneNumber });

		if (phoneNumber?.length < value.length) {
			if (value.length === 3 || value.length === 7) {
				value += '-';
			}
		}

		this.setState({ phoneNumber: value });
	}

	async deleteAccount() {
		const email = getCookie('email');
		const password = getCookie('password');

		const { error } = await web.deleteAccount({
			email,
			password,
		});

		if (error) {
			console.error(error);

			return;
		}

		window.location = '/';
	}

	render() {
		const {
			phoneNumber,
			errors,
			loading,
			allowSMSNotifications,
			allowEmailNotifications,
			loaded,
		} = this.state;

		return (
			loaded && (
				<div className='user'>
					<div className='user-section'>
						<Input
							pattern='[0-9]*'
							label='Phone Number'
							placeholder={'xxx-xxx-xxxx'}
							type='tel'
							value={phoneNumber}
							error={errors.phoneNumber}
							onChange={this.handleChangePhoneNumber.bind(this)}
						/>
						<Button
							label='Update Phone Number'
							icon={faPhone}
							loading={loading.phoneNumber}
							onClick={this.updatePhoneNumber.bind(this)}
							primary
						/>
					</div>
					<Toggle
						label='Allow SMS Notifications'
						defaultChecked={allowSMSNotifications}
						onChange={this.updateAllowSMSNotifications.bind(this)}
					/>
					<span className='section-subtitle'>
						By enabling "Allow SMS Notifications" you are agreeing
						to receive text messages from our automated services
					</span>
					<Toggle
						label='Allow Email Notifications'
						defaultChecked={allowEmailNotifications}
						onChange={this.updateAllowEmailNotifications.bind(this)}
					/>
					<span className='section-subtitle'>
						By enabling "Allow Email Notifications" you are agreeing
						to receive emails from our automated services
					</span>
					<div className='user-section'>
						<Button
							label='Logout'
							icon={faArrowRightFromBracket}
							onClick={this.logout.bind()}
						/>
						<Button
							label='Delete Account'
							danger
							icon={faUserXmark}
							onClick={this.deleteAccount.bind(this)}
						/>
					</div>
				</div>
			)
		);
	}
}

export default User;
