import { Component } from 'react';
import Input from 'Components/shared/Input';
import Button from 'Components/shared/Button';
import { setCookie } from 'modules/cookies';
import './Form.scss';
import { Link } from 'react-router-dom';
import Dropdown from 'Components/shared/Dropdown';

class Form extends Component {
	constructor() {
		super();

		this.state = {
			values: {},
			errors: {},
			hasSubmitted: false,
			loading: false,
		};
	}

	checkErrors() {
		if (!this.state.hasSubmitted) return;

		const { inputs } = this.props;
		const { values } = this.state;
		let errors = {};

		// Check for non-empty required fields
		inputs.forEach((input) => {
			if (input.required && !values[input.variable]) {
				errors[input.label] = 'This field is required.';
			}
		});

		// Check for matching passwords
		inputs.forEach((input) => {
			if (input.confirmFor) {
				const confirmValue = values[input.variable];
				const passwordValue = values[input.confirmFor];

				if (confirmValue !== passwordValue) {
					errors[input.label] = 'Passwords do not match.';
				}
			}
		});

		// Check for minimum password length
		inputs.forEach((input) => {
			if (input.label === 'Password') {
				const passwordValue = values[input.variable] || '';

				if (passwordValue.length < 8) {
					errors[input.label] =
						'Password must be at least 8 characters.';
				}
			}

			if (input.validate) {
				const { validator, message } = input.validate;

				if (!validator(values[input.variable])) {
					errors[input.label] = message;
				}
			}
		});

		this.setState({
			errors,
		});

		return Object.keys(errors).length > 0;
	}

	setLoading(loading) {
		this.setState({ loading });
	}

	submit() {
		const { onSubmit } = this.props;
		const { values } = this.state;

		this.setState({ hasSubmitted: true, error: null }, async () => {
			const hasErrors = this.checkErrors();

			if (hasErrors) return;

			if (onSubmit) {
				const response = await onSubmit(
					this.setLoading.bind(this),
					values
				);

				if (response?.error) {
					this.setState({ error: response.error });

					return;
				}
			}

			window.location = '/';
		});
	}

	handleChange(input, value) {
		if (input.prefix) value = value.replaceAll(input.prefix, '');

		if (input.number && value) {
			value = parseFloat(value);
		}

		const { values } = this.state;

		values[input.variable] = value;

		this.setState({ values }, this.checkErrors.bind(this));
	}

	render() {
		const { title, subtitle, inputs, alternativeLink } = this.props;
		const { errors, loading, error } = this.state;

		const inputsMap = inputs.map((input, index) => {
			const isError = errors[input.label];
			const value = this.state.values[input.variable] || '';

			const isRequiredFor =
				input.requiredFor &&
				this.state.values[input.requiredFor] &&
				true;

			return (
				<div
					className={`form-input form-input-${input.type}`}
					key={index}>
					{input.type === 'submit' ? (
						<Button
							primary
							{...input}
							onClick={this.submit.bind(this)}
							loading={loading}
						/>
					) : input.type == 'caption' ? (
						<div className='caption-container'>
							<span className='form-caption'>{input.label}</span>
						</div>
					) : input.type == 'dropdown' ? (
						<Dropdown
							label={input.label}
							options={input.options}
							defaultValue={input.default}
						/>
					) : (
						<Input
							{...input}
							required={input.required ?? isRequiredFor}
							error={isError}
							value={input.prefix ? input.prefix + value : value}
							onChange={(value) => {
								this.handleChange(input, value || '');
							}}
						/>
					)}
				</div>
			);
		});

		return (
			<div className='form-container'>
				<div className='form'>
					<div className='form-header'>
						<h1 className='form-title'>{title}</h1>
						<span className='form-subtitle'>{subtitle}</span>
					</div>
					<div className='form-inputs'>{inputsMap}</div>
					{error && <span className='form-error'>{error}</span>}
				</div>
				{alternativeLink && (
					<Link className='alternate-action' to={alternativeLink.to}>
						{alternativeLink.text}
					</Link>
				)}
			</div>
		);
	}
}

export default Form;
