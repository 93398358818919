import { Component } from 'react';
import './Toggle.scss';

class Toggle extends Component {
	constructor({ defaultChecked, label }) {
		super();

		this.state = {
			toggled: defaultChecked ?? false,
		};
	}

	setToggled() {
		const { onChange } = this.props;
		const { toggled } = this.state;

		if (onChange) {
			onChange(!toggled);
		}

		this.setState({ toggled: !toggled });
	}

	render() {
		const { toggled } = this.state;
		const { label, className, defaultChecked, ...rest } = this.props;

		return (
			<div className={`toggle ${className}`} {...rest}>
				<span className='toggle-label'>{label}</span>

				<label className='toggle-switch'>
					<input
						className='toggle-checkbox'
						type='checkbox'
						defaultChecked={defaultChecked}
						onClick={this.setToggled.bind(this)}
					/>
					<span className='toggle-slider' />
				</label>
			</div>
		);
	}
}

export default Toggle;
