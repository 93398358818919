import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Navbar.scss';

class Navbar extends Component {
	render() {
		return (
			<div className='navbar'>
				<Link to='/'>
					<div className='navbar-item navbar-home'>
						<FontAwesomeIcon icon={faHome} />
					</div>
				</Link>
				<Link to='/addItem'>
					<div className='navbar-item navbar-add'>
						<FontAwesomeIcon icon={faPlus} />
					</div>
				</Link>
				<Link to='/user'>
					<div className='navbar-item navbar-user'>
						<FontAwesomeIcon icon={faUser} />
					</div>
				</Link>
			</div>
		);
	}
}

export default Navbar;
